import React, { FC } from 'react';
import { Tab, Icon, Message, Segment } from 'semantic-ui-react';
import DebugVfaFrontend from '@/components/DebugVfaFrontend';
import DebugNbWrites from '@/components/DebugNbWrites';
import DebugAwsZaps from '@/components/DebugAwsZaps';
import styled from '@emotion/styled';

export const FloatedDiv = styled.div<{ position: string }>`
  float: ${(props) => props.position};
  background-position: 2px;
  padding: 5px;
  border: none;
  color: blue;
  padding-left: 25px;
  position: relative;
`;

export const SqlSegment = styled(Segment)`
  border: none !important;
  box-shadow: none !important;
  color: black;
  font-family: monospace;
`;

const panes = [
  {
    menuItem: 'AWS Zap History',
    render: () => (
      <Tab.Pane>
        <Segment>
          <DebugAwsZaps />
        </Segment>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'AWS NB Writes',
    render: () => (
      <Tab.Pane>
        <Segment>
          <DebugNbWrites />
        </Segment>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'VFA Bridge',
    render: () => (
      <Tab.Pane>
        <Segment>
          <DebugVfaFrontend />
        </Segment>
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'VFA Bridge Rerun',
    render: () => (
      <Tab.Pane>
        <Segment>
          There could be a form here to enter the aws_request_id (aka
          created_by) that failed in the Bridge with a Button to rerun it
          through the Bridge code, optionally with an override for email
          addresses that are incorrect.
        </Segment>
      </Tab.Pane>
    ),
  },
];

const DebugTab: FC = () => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = 'VFA Dashboard (AWS Debug Info)';
    }
  }, []);

  return (
    <Segment>
      <Message warning icon visible>
        <Icon name="warning sign" />
        <Message.Content>
          Results may contain confidential personally identifiable information
          (PII). CONFIDENTIAL.
        </Message.Content>
      </Message>
      <Tab
        menu={{ color: 'blue', fluid: true, vertical: false, tabular: true }}
        panes={panes}
      />
    </Segment>
  );
};

export default DebugTab;
