import React, { FC, useContext, useState } from 'react';
import {
  Form,
  Input,
  Header,
  Button,
  Message,
  Segment,
  Item,
  Icon,
  ItemContent,
  ItemHeader,
  ItemMeta,
  ItemDescription,
  ItemExtra,
  Popup,
  ItemGroup,
} from 'semantic-ui-react';
import ReactJson from 'react-json-view';
import UserContext from '@/components/UserContext';
import DbContext from '@/components/DbContext';
import { getBackendConfig } from '@/lib/lambda';
import { FloatedDiv, SqlSegment } from '@/pages/index/DebugTab';
import { callGetApi } from '@/lib/paginated';
import { format } from 'date-fns';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import extractDateString from '@/lib/extractDateString';

const year = new Date().getUTCFullYear();
const startDateUtc = new Date(`${year}-01-01`);
startDateUtc.setTime(
  startDateUtc.getTime() + startDateUtc.getTimezoneOffset() * 60 * 1000,
);

const INITIAL_FORM_STATE = {
  nbid: '123456',
  startDate: startDateUtc,
  endDate: new Date(),
};

const DebugAwsZaps: FC = () => {
  const [errorMsg] = useState<string | null>(null);
  const [nbid, setNbid] = useState(INITIAL_FORM_STATE.nbid);
  const [startDate, setStartDate] = useState(INITIAL_FORM_STATE.startDate);
  const [endDate, setEndDate] = useState(INITIAL_FORM_STATE.endDate);

  return (
    <Form>
      <Header as="h2">AWS Zap History</Header>
      <Form.Field required className="inline field">
        <label>NBID:</label>
        <Input
          placeholder="NBID"
          value={nbid}
          onChange={(_evt, data) => setNbid(data.value)}
        />
      </Form.Field>
      <Form.Field required className="inline field">
        <label>Start date:</label>
        <SemanticDatepicker
          value={startDate}
          onChange={(_evt, data) => setStartDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field required className="inline field">
        <label>End date:</label>
        <SemanticDatepicker
          value={endDate}
          onChange={(_evt, data) => setEndDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field>
        <GenerateDebugAwsZapsData
          nbid={nbid}
          startDate={startDate}
          endDate={endDate}
        />
      </Form.Field>
      {errorMsg && <Message color="red">{errorMsg}</Message>}
    </Form>
  );
};

interface GenerateDebugAwsZapsProps {
  startDate: Date | null;
  endDate: Date | null;
  nbid: string;
}

function CreatedUpdatedZapResultItem({ row }) {
  // return <Item key={data?.aws_request_id}>{data?.aws_request_id} Hi Heather</Item>;
  const formattedDate = format(
    Date.parse(row.created_at),
    'EEEE MMMM do, yyyy H:mm:ss',
  );

  return (
    <Item>
      <Icon
        color={row.created_or_updated === 'doCreate' ? 'yellow' : 'grey'}
        name={row.created_or_updated === 'doCreate' ? 'lightning' : 'pencil'}
      />
      <Icon />
      <ItemContent>
        <ItemHeader>{formattedDate}</ItemHeader>
        <ItemMeta style={{ color: 'gray' }}>{row.created_at}</ItemMeta>
        <ItemDescription>
          Entered with is_from: <b>{row.is_from}</b>
        </ItemDescription>
        <ItemDescription>
          Changed variables:{row.changed_variables}
        </ItemDescription>
        <FloatedDiv position="left">
          <ItemExtra>
            <ReactJson
              name="new person attributes set by zap"
              src={row.person.person}
              theme="summerfruit:inverted"
              collapsed={1}
              sortKeys
              quotesOnKeys={false}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </ItemExtra>
          <ItemExtra>
            <ReactJson
              name="original person received by zap"
              src={row.raw}
              theme="summerfruit:inverted"
              collapsed={0}
              sortKeys
              quotesOnKeys={false}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </ItemExtra>
        </FloatedDiv>

        <FloatedDiv position="left">
          <ItemDescription style={{ color: '#aaaaaa' }}>
            <Popup
              trigger={<text>{row.aws_request_id}</text>}
              position="bottom right"
            >
              aws_request_id (aka created_by)
            </Popup>
          </ItemDescription>
          <ItemDescription style={{ color: 'black' }}>
            <Popup
              trigger={<text>{row.requester}</text>}
              position="bottom right"
            >
              requester
            </Popup>
          </ItemDescription>
        </FloatedDiv>
      </ItemContent>
    </Item>
  );
}

function EventRsvpZapResultItem({ row }) {
  // return <Item key={data?.aws_request_id}>{data?.aws_request_id} Hi Heather</Item>;
  const formattedDate = format(
    Date.parse(row.created_at),
    'EEEE MMMM do, yyyy H:mm:ss',
  );

  return (
    <Item>
      <ItemContent>
        <ItemHeader>{formattedDate}</ItemHeader>
        <ItemMeta style={{ color: 'gray' }}>{row.created_at}</ItemMeta>
        <FloatedDiv position="left">
          <ItemExtra>
            <ReactJson
              name="raw"
              src={row.raw}
              theme="summerfruit:inverted"
              collapsed={1}
              sortKeys
              quotesOnKeys={false}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </ItemExtra>
        </FloatedDiv>

        <FloatedDiv position="left">
          <ItemDescription style={{ color: '#aaaaaa' }}>
            <Popup
              trigger={<text>{row.aws_request_id}</text>}
              position="bottom right"
            >
              aws_request_id (aka created_by)
            </Popup>
          </ItemDescription>
        </FloatedDiv>
      </ItemContent>
    </Item>
  );
}

function ContactedZapResultItem({ row }) {
  // return <Item key={data?.aws_request_id}>{data?.aws_request_id} Hi Heather</Item>;
  const formattedDate = format(
    Date.parse(row.created_at),
    'EEEE MMMM do, yyyy H:mm:ss',
  );

  return (
    <Item>
      <ItemContent>
        <ItemHeader>{formattedDate}</ItemHeader>
        <ItemMeta style={{ color: 'gray' }}>{row.created_at}</ItemMeta>
        <ItemDescription>
          Contact type: <b>{row.contact_type}</b>
        </ItemDescription>
        <ItemDescription>
          Home country code: {row.home_country_code}
        </ItemDescription>
        <ItemDescription>Tags: {row.tags}</ItemDescription>
        <FloatedDiv position="left">
          <ItemExtra>
            <ReactJson
              name="person_call"
              src={row.raw.person_call}
              theme="summerfruit:inverted"
              collapsed={0}
              sortKeys
              quotesOnKeys={false}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </ItemExtra>
        </FloatedDiv>

        <FloatedDiv position="left">
          <ItemDescription style={{ color: '#aaaaaa' }}>
            <Popup
              trigger={<text>{row.aws_request_id}</text>}
              position="bottom right"
            >
              aws_request_id (aka created_by)
            </Popup>
          </ItemDescription>
        </FloatedDiv>
      </ItemContent>
    </Item>
  );
}

function CallhubZapResultItem({ row }) {
  // return <Item key={data?.aws_request_id}>{data?.aws_request_id} Hi Heather</Item>;
  const formattedDate = format(
    Date.parse(row.created_at),
    'EEEE MMMM do, yyyy H:mm:ss',
  );

  return (
    <Item>
      <ItemContent>
        <ItemHeader>{formattedDate}</ItemHeader>
        <ItemMeta style={{ color: 'gray' }}>{row.created_at}</ItemMeta>
        <ItemDescription>
          Caller: {row.caller_username} (nbid {row.caller_nbid})
        </ItemDescription>
        <ItemDescription>
          Called: {row.email} (country {row.country_code}, voting state{' '}
          {row.voting_state})
        </ItemDescription>
        <ItemDescription>
          Disposition: <b>{row.disposition}</b>.
        </ItemDescription>
        <ItemDescription>Notes: {row.notes}</ItemDescription>
        <FloatedDiv position="left">
          <ItemExtra>
            <ReactJson
              name="original callhub note received by zap"
              src={row.raw}
              theme="summerfruit:inverted"
              collapsed={0}
              sortKeys
              quotesOnKeys={false}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </ItemExtra>
        </FloatedDiv>

        <FloatedDiv position="left">
          <ItemDescription style={{ color: '#aaaaaa' }}>
            <Popup
              trigger={<text>{row.aws_request_id}</text>}
              position="bottom right"
            >
              aws_request_id (aka created_by)
            </Popup>
          </ItemDescription>
        </FloatedDiv>
      </ItemContent>
    </Item>
  );
}

const INITIAL_RESULT_DATA = {
  zap_callhub: { query: { string: '' }, count: null, results: [] },
  zap_nb_contacted: { query: { string: '' }, count: null, results: [] },
  zap_nb_created_updated: { query: { string: '' }, count: null, results: [] },
  zap_event_rsvp: { query: { string: '' }, count: null, results: [] },
};

const GenerateDebugAwsZapsData: FC<GenerateDebugAwsZapsProps> = ({
  nbid,
  startDate,
  endDate,
}) => {
  const userContext = useContext(UserContext);
  const dbContext = useContext(DbContext);

  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [resultData, setResultData] = useState(INITIAL_RESULT_DATA);

  const initStartQuery = async () => {
    if (isSending) return;
    setIsSending(true);
    setIsLoading(true);
    setResultData(INITIAL_RESULT_DATA);
    setErrorMsg(null);

    try {
      const data = {
        nbid: nbid || undefined,
        startDate: extractDateString(startDate),
        endDate: extractDateString(endDate),
      };
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      let result = null as any;
      try {
        result = (await callGetApi(
          dbContext.db,
          `debug-aws-zaps`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          'API Error. Maybe your login has expired? Try refreshing the page.  Email hpiwowar@gmail.com to report this error or for additional help.',
        );
        console.log('API error');
      }
      if (result?.zap_nb_created_updated.count === undefined) {
        alert(
          'API Error. Maybe your login has expired? Try refreshing the page.  Email hpiwowar@gmail.com to report this error or for additional help.',
        );
        throw new Error('bad API response');
      }
      setIsSending(false);
      setIsLoading(false);
      setResultData(result);
    } catch (error) {
      setIsSending(false);
      setIsLoading(false);
      console.log('query failed ->', error);
      if (error.errors?.length > 0) {
        setErrorMsg(error.errors[0].message);
      }
    }
  };

  const [showRawData, setShowRawData] = React.useState(false);

  return (
    <>
      <Button
        primary
        content="Lookup Data"
        disabled={isLoading}
        loading={isLoading}
        onClick={() => initStartQuery()}
      />
      <br />

      {resultData.zap_callhub.results && (
        <Header as="h2">AWS CallHub Zap</Header>
      )}
      {resultData.zap_callhub.count != null && (
        <text>
          <b>Found {resultData.zap_callhub.count} results:</b>
        </text>
      )}
      <ItemGroup relaxed>
        {resultData.zap_callhub.results &&
          resultData.zap_callhub.results.map((row) => (
            <CallhubZapResultItem key={row?.aws_request_id} row={row} />
          ))}
      </ItemGroup>
      {resultData.zap_callhub.results.length === 0 && <text>No results.</text>}
      {errorMsg && <Message color="red">{errorMsg}</Message>}
      {resultData.zap_callhub.query.string !== '' && (
        <SqlSegment>
          <hr />
          SQL: {resultData.zap_callhub.query.string}
          <hr />
          <Button onClick={() => setShowRawData((state) => !state)}>
            Show/Hide Raw Data
          </Button>
          {showRawData && (
            <Segment>
              <pre>{JSON.stringify(resultData.zap_callhub, null, 2)}</pre>
            </Segment>
          )}
        </SqlSegment>
      )}

      {resultData.zap_nb_contacted.results && (
        <Header as="h2">AWS NB Contacted Zap</Header>
      )}
      {resultData.zap_nb_contacted.count != null && (
        <text>
          <b>Found {resultData.zap_nb_contacted.count} results:</b>
        </text>
      )}
      <ItemGroup relaxed>
        {resultData.zap_nb_contacted.results &&
          resultData.zap_nb_contacted.results.map((row) => (
            <ContactedZapResultItem key={row?.aws_request_id} row={row} />
          ))}
      </ItemGroup>
      {resultData.zap_nb_contacted.results.length === 0 && (
        <text>No results.</text>
      )}
      {errorMsg && <Message color="red">{errorMsg}</Message>}
      {resultData.zap_nb_contacted.query.string !== '' && (
        <SqlSegment>
          <hr />
          SQL: {resultData.zap_nb_contacted.query.string}
          <hr />
          <Button onClick={() => setShowRawData((state) => !state)}>
            Show/Hide Raw Data
          </Button>
          {showRawData && (
            <Segment>
              <pre>{JSON.stringify(resultData.zap_nb_contacted, null, 2)}</pre>
            </Segment>
          )}
        </SqlSegment>
      )}

      {resultData.zap_nb_created_updated.results && (
        <Header as="h2">AWS NB Created/Updated Zap</Header>
      )}
      {resultData.zap_nb_created_updated.count != null && (
        <text>
          <b>Found {resultData.zap_nb_created_updated.count} results:</b>
        </text>
      )}
      <ItemGroup relaxed>
        {resultData.zap_nb_created_updated.results &&
          resultData.zap_nb_created_updated.results.map((row) => (
            <CreatedUpdatedZapResultItem key={row?.aws_request_id} row={row} />
          ))}
      </ItemGroup>
      {resultData.zap_nb_created_updated.results.length === 0 && (
        <text>No results.</text>
      )}
      {errorMsg && <Message color="red">{errorMsg}</Message>}
      {resultData.zap_nb_created_updated.query.string !== '' && (
        <SqlSegment>
          <hr />
          SQL: {resultData.zap_nb_created_updated.query.string}
          <hr />
          <Button onClick={() => setShowRawData((state) => !state)}>
            Show/Hide Raw Data
          </Button>
          {showRawData && (
            <Segment>
              <pre>
                {JSON.stringify(resultData.zap_nb_created_updated, null, 2)}
              </pre>
            </Segment>
          )}
        </SqlSegment>
      )}

      {resultData.zap_event_rsvp.results && (
        <Header as="h2">AWS Event RSVP Zap</Header>
      )}
      {resultData.zap_event_rsvp.count != null && (
        <text>
          <b>Found {resultData.zap_event_rsvp.count} results:</b>
        </text>
      )}
      <ItemGroup relaxed>
        {resultData.zap_event_rsvp.results &&
          resultData.zap_event_rsvp.results.map((row) => (
            <EventRsvpZapResultItem key={row?.aws_request_id} row={row} />
          ))}
      </ItemGroup>
      {resultData.zap_event_rsvp.results.length === 0 && (
        <text>No results.</text>
      )}
      {errorMsg && <Message color="red">{errorMsg}</Message>}
      {resultData.zap_event_rsvp.query.string !== '' && (
        <SqlSegment>
          <hr />
          SQL: {resultData.zap_event_rsvp.query.string}
          <hr />
          <Button onClick={() => setShowRawData((state) => !state)}>
            Show/Hide Raw Data
          </Button>
          {showRawData && (
            <Segment>
              <pre>{JSON.stringify(resultData.zap_event_rsvp, null, 2)}</pre>
            </Segment>
          )}
        </SqlSegment>
      )}
    </>
  );
};

export default DebugAwsZaps;
