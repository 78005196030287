import React, { FC, useContext, useState } from 'react';
import {
  Form,
  Input,
  Header,
  Button,
  Message,
  Segment,
  ItemGroup,
  Item,
  Icon,
  ItemContent,
  ItemHeader,
  ItemMeta,
  ItemExtra,
  ItemDescription,
  Popup,
} from 'semantic-ui-react';
import ReactJson from 'react-json-view';
import UserContext from '@/components/UserContext';
import DbContext from '@/components/DbContext';
import { getBackendConfig } from '@/lib/lambda';
import { FloatedDiv, SqlSegment } from '@/pages/index/DebugTab';
import { callGetApi } from '@/lib/paginated';
import { format } from 'date-fns';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import extractDateString from '@/lib/extractDateString';

const year = new Date().getUTCFullYear();
const startDateUtc = new Date(`${year}-01-01`);
startDateUtc.setTime(
  startDateUtc.getTime() + startDateUtc.getTimezoneOffset() * 60 * 1000,
);

const INITIAL_FORM_STATE = {
  nbid: '123456',
  startDate: startDateUtc,
  endDate: new Date(),
};

const DebugNbWrites: FC = () => {
  const [errorMsg] = useState<string | null>(null);
  const [nbid, setNbid] = useState(INITIAL_FORM_STATE.nbid);
  const [startDate, setStartDate] = useState(INITIAL_FORM_STATE.startDate);
  const [endDate, setEndDate] = useState(INITIAL_FORM_STATE.endDate);

  return (
    <Form>
      <Header as="h2">AWS Writes To Nationbuilder via API</Header>
      <Form.Field required className="inline field">
        <label>NBID:</label>
        <Input
          placeholder="NBID"
          value={nbid}
          onChange={(_evt, data) => setNbid(data.value)}
        />
      </Form.Field>
      <Form.Field required className="inline field">
        <label>Start date:</label>
        <SemanticDatepicker
          value={startDate}
          onChange={(_evt, data) => setStartDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field required className="inline field">
        <label>End date:</label>
        <SemanticDatepicker
          value={endDate}
          onChange={(_evt, data) => setEndDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field>
        <GenerateDebugNbWritesData
          nbid={nbid}
          startDate={startDate}
          endDate={endDate}
        />
      </Form.Field>
      {errorMsg && <Message color="red">{errorMsg}</Message>}
    </Form>
  );
};

interface GenerateDebugNbWritesProps {
  startDate: Date | null;
  endDate: Date | null;
  nbid: string;
}

function ResultItem({ row }) {
  // return <Item key={data?.aws_request_id}>{data?.aws_request_id} Hi Heather</Item>;
  const formattedDate = format(
    Date.parse(row.created),
    'EEEE MMMM do, yyyy H:mm:ss',
  );

  let endpointSummary = '/people';
  if (row.endpoint.includes('tagging')) {
    endpointSummary = '/tag';
  } else if (row.endpoint.includes('survey')) {
    endpointSummary = '/survey';
  } else if (row.endpoint.includes('membership')) {
    endpointSummary = '/membership';
  }

  return (
    <Item>
      <Icon
        color={row.status_code < 300 ? 'green' : 'red'}
        name={row.status_code < 300 ? 'check' : 'x'}
      />
      <Icon
        color={row.verb === 'POST' ? 'yellow' : 'grey'}
        name={
          // eslint-disable-next-line no-nested-ternary
          row.verb === 'POST'
            ? 'lightning'
            : row.verb === 'PUT'
            ? 'pencil'
            : 'trash alternate outline'
        }
      />
      Try #{row.tries}
      <Icon />
      <ItemContent>
        <ItemHeader>{formattedDate}</ItemHeader>
        <ItemMeta style={{ color: 'gray' }}>{row.created}</ItemMeta>
        <ItemDescription>
          Got {row.status_code} calling {row.verb} {endpointSummary} with{' '}
          {Object.keys(Object.values(row.payload_jsonb)[0]).length} attributes
        </ItemDescription>
        <FloatedDiv position="left">
          <ItemExtra>
            <ReactJson
              name={false}
              src={row.payload_jsonb}
              theme="summerfruit:inverted"
              collapsed={1}
              sortKeys
              quotesOnKeys={false}
              enableClipboard={false}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </ItemExtra>
        </FloatedDiv>

        <FloatedDiv position="left">
          <ItemDescription style={{ color: '#aaaaaa' }}>
            <Popup
              trigger={<text>{row.aws_request_id}</text>}
              position="bottom right"
            >
              aws_request_id (aka created_by)
            </Popup>
          </ItemDescription>
          <ItemDescription style={{ color: '#aaaaaa' }}>
            <Popup trigger={<text>{row.vfa_id}</text>} position="bottom right">
              vfa_id (aka id) or source Zap name
            </Popup>
          </ItemDescription>
        </FloatedDiv>
      </ItemContent>
    </Item>
  );
}

const GenerateDebugNbWritesData: FC<GenerateDebugNbWritesProps> = ({
  nbid,
  startDate,
  endDate,
}) => {
  const userContext = useContext(UserContext);
  const dbContext = useContext(DbContext);

  const [isSending, setIsSending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [resultData, setResultData] = useState({
    query: { string: '' },
    count: null,
    results: [],
  });

  const initStartQuery = async () => {
    if (isSending) return;
    setIsSending(true);
    setIsLoading(true);
    setResultData({ query: { string: '' }, count: null, results: [] });
    setErrorMsg(null);

    try {
      const data = {
        nbid: nbid || undefined,
        startDate: extractDateString(startDate),
        endDate: extractDateString(endDate),
      };
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      let result = null as any;
      try {
        result = (await callGetApi(
          dbContext.db,
          `debug-nb-writes`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          'API Error. Maybe your login has expired? Try refreshing the page.  Email hpiwowar@gmail.com to report this error or for additional help.',
        );
        console.log('API error');
      }
      if (result?.count == null) {
        alert(
          'API Error. Maybe your login has expired? Try refreshing the page.  Email hpiwowar@gmail.com to report this error or for additional help.',
        );
        throw new Error('bad API response');
      }
      setIsSending(false);
      setIsLoading(false);
      setResultData(result);
    } catch (error) {
      setIsSending(false);
      setIsLoading(false);
      console.log('query failed ->', error);
      if (error.errors?.length > 0) {
        setErrorMsg(error.errors[0].message);
      }
    }
  };

  const [showRawData, setShowRawData] = React.useState(false);

  return (
    <>
      <Button
        primary
        content="Lookup Data"
        disabled={isLoading}
        loading={isLoading}
        onClick={() => initStartQuery()}
      />
      <br />
      <br />
      {resultData.count != null && (
        <text>
          <b>Found {resultData.count} results:</b>
        </text>
      )}
      <ItemGroup relaxed>
        {resultData.results.map((row) => (
          <ResultItem key={row?.aws_request_id} row={row} />
        ))}
      </ItemGroup>
      {errorMsg && <Message color="red">{errorMsg}</Message>}
      {resultData.query.string !== '' && (
        <SqlSegment>
          <hr />
          SQL: {resultData.query.string}
          <hr />
          <Button onClick={() => setShowRawData((state) => !state)}>
            Show/Hide Raw Data
          </Button>
          {showRawData && (
            <Segment>
              <pre>{JSON.stringify(resultData, null, 2)}</pre>
            </Segment>
          )}
        </SqlSegment>
      )}
    </>
  );
};

export default DebugNbWrites;
